const isUnprintable=(ebcdicByte)=> {
    if (
      ebcdicByte < 64 ||
      (ebcdicByte > 64 && ebcdicByte < 74) ||
      (ebcdicByte > 80 && ebcdicByte < 90) ||
      (ebcdicByte > 97 && ebcdicByte < 106) ||
      (ebcdicByte > 111 && ebcdicByte < 121)
    )
      return true;
    else return false;
  }
  export{isUnprintable}