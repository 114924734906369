import React, { useState, useContext } from "react";
import "./NavBar.css";
import FileEditor from "../aceeditor/FileEditor";
import {
  DefaultButton,
  Dialog,
  Dropdown,
  Stack,
  TextField,
  Label,
  PrimaryButton,
  ContextualMenu,
} from "@fluentui/react";

import image from "./cloudframe.png";
import FileEditorContext from "../contextApi/FileEditorContext.js";
import "../aceeditor/FileEditor.css";
import AceEditor from "react-ace";
import "brace/ext/searchbox";

const View = () => {
  //Defining contextApi
  const fileEditorContext = useContext(FileEditorContext);
  const { editorRef, colIndexRef } = fileEditorContext;
  


  //For enabling the offset
  const handleClickEnable = () => {
    let offsetEnable = document.querySelector(".col_index");
    offsetEnable.style.display = "block";
    editorRef.current.editor.session.on("changeScrollLeft", (e) => {
      const colIndexDiv = colIndexRef.current;
      const scrollLeft = e;
      colIndexDiv.scrollLeft = scrollLeft;

      // console.log("Horizontal scroll", e);
    });
    editorRef.current?.editor.session.off("changeScrollLeft", () => null);
    offsetEnable.style.display = "block";

    let editorWidth = document.querySelector("#ace-editor");
    editorWidth.style.height = "84.6vh";
  };

  //For disabling the offset
  const handleClickDisable = () => {
    let offsetDisable = document.querySelector(".col_index");
    offsetDisable.style.display = "none";

    let editorWidth = document.querySelector("#ace-editor");
    editorWidth.style.height = "90.6vh";
  };

  const handleSearch=()=>{
    const editor = editorRef.current.editor;
    editor.execCommand("find");

  }

  // const handleGotoLine=()=>{
    
  // }

  const menuItems = [
    {
      key: "scale",
      text: "Scale",
      subMenuProps: {
        items: [
          {
            key: "enable",
            text: "Enable",
            title: "Enable the scale",
            onClick: handleClickEnable,
          },
          {
            key: "disable",
            text: "Disable",
            title: "Disable the scale",
            onClick: handleClickDisable,
          },
        ],
      },
    },
    {
      key: "search",
      text: "Search " + "\u00A0".repeat(17) + " Ctrl+f",

      onClick:handleSearch
    }
    
    // ,{
    //   key: "gotoline",
    //   text: "Goto",
    //   onClick:handleGotoLine
    // }
  ];

  const menuProps = {
    shouldFocusOnMount: true,
    items: menuItems,
  };

  return (
    <li className="navbar-item">
      <DefaultButton
        className="dropdown-button"
        text="View"
        menuProps={menuProps}
      ></DefaultButton>
    </li>
  );
};

export default View;
