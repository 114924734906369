import React, { useState } from "react";
import "./NavBar.css";
import FileEditor from "../aceeditor/FileEditor";
import {
  DefaultButton,
  Dialog,
  Dropdown,
  Stack,
  TextField,
  Label,
  PrimaryButton,
} from "@fluentui/react";
import image from "./cloudframe.png";
import View from "./View";



const NavBar = (props) => {
  const [showFileDropdown, setShowFileDropdown] = React.useState(false);
  const [fileType, setFileType] = React.useState("");
  const [recordLength, setRecordLength] = React.useState("");
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [showRecordLengthDialog, setShowRecordLengthDialog] =
    React.useState(false);
  const dropdownRef = React.useRef(null);
  const [fileData, setFileData] = React.useState("");

  const toggleFileDropdown = () => {
  
 
    if (!showFileDropdown) {
      setFileType("");
      setRecordLength("");
      setSelectedFile(null);
    }
    setShowFileDropdown(!showFileDropdown);
  };

  const handleFileTypeChange = (event, option) => {
    setFileType(option.key);
  };
  const handleRecordLengthChange = (event) => {
    setRecordLength(event.target.value);
  };
  const handleFileUpload = (event) => {
 
    var input = document.getElementById("files");
    var files = input.files;
      var fileData1 = new Blob([files[0]]);
    // setFileData(files[0]);
    setFileData(files);
    console.log(fileData1)
    setSelectedFile(event.target.files[0]);
    props.handleFileName(files[0].name);
    props.handler(files, recordLength,fileType);
    setShowFileDropdown(false);

  };



  const handleDocumentClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowFileDropdown(false);
    }
  };

 



  React.useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);
  const handleRecordLengthDialogSave = () => {
    // Additional logic for saving record length
    setShowRecordLengthDialog(false); // Close the dialog after saving
  };

  return (
    <nav className="navbar">
      {/* <div className="navbar-logo">
        <img src={image} alt="Logo" style={{width:"200px",marginTop:"5px"}} />
      </div> */}
      <ul className="navbar-list">
        <li className="navbar-item">
          <div className="dropdown" ref={dropdownRef}>
            <DefaultButton
              className="dropdown-button"
              onClick={toggleFileDropdown}
            >
              File
            </DefaultButton>
            {showFileDropdown && (
              <div className="sub-dropdown">
                <div className="sub-dropdown-content">
                      <Label className="sub-dropdown-label">
                        Select File Type:
                      </Label>
                      <Dropdown
                        placeholder="Select"
                        options={[
                          // { key: '', text: 'Select' },
                          { key: "fb", text: "FB File" },
                          { key: "vb", text: "VB File" },
                        ]}
                        selectedKey={fileType}
                        onChange={handleFileTypeChange}
                      />
                      {fileType === "fb" && (
                        <div>
                          <PrimaryButton
                            text="Enter Record Length"
                            className="record-length-button"
                            onClick={() => setShowRecordLengthDialog(true)}
                          />
                        </div>
                      )}
                      {selectedFile && (
                        <div className="sub-dropdown-content">
                          <Label className="sub-dropdown-label">
                            Selected File:
                          </Label>
                          <p className="sub-dropdown-text">
                            {selectedFile.name}
                          </p>
                        </div>
                      )}
                      <div className="sub-dropdown-content">
                        <Label className="sub-dropdown-label">
                          Upload File:
                        </Label>
                        <input
                          className="sub-dropdown-input"
                          type="file"
                          id="files"
                          onChange={handleFileUpload}
                        />
                      </div>
      
                </div>
              </div>
            )}
          </div>
        </li>
          <View/>
        <li className="navbar-item"><DefaultButton className="dropdown-button">Edit</DefaultButton></li>
        <li className="navbar-item"><DefaultButton className="dropdown-button">Help</DefaultButton></li>

      </ul>
      <Dialog
        hidden={!showRecordLengthDialog}
        onDismiss={() => setShowRecordLengthDialog(false)}
        title="Enter Record Length"
      >
                <TextField
          label="Record Length"
          value={recordLength}
          onChange={handleRecordLengthChange}
        />
        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
          <PrimaryButton text="Ok" className="save-cancel-button" onClick={handleRecordLengthDialogSave} />
          <DefaultButton text="Cancel" className="save-cancel-button" onClick={() => setShowRecordLengthDialog(false)} />
        </Stack>
      </Dialog>
    </nav>
  );
};

export default NavBar;