import React, { useContext, useRef, useState } from "react";

const FileEditorContext = React.createContext();

export const FileEditorApi = (props) => {
  const editorRef = useRef();
  const colIndexRef = useRef();
  const [offsetWidth, setoffsetWidth] = useState(20);
  const [lineNumber, setLineNumber] = useState(0);
  const [hexaFirst, setHexaFirst] = useState([]);



  const updateoffsetWidth = (e) => setoffsetWidth(e);
  const updatelineNumber = (e) => setLineNumber(e);
  const updatehexaFirst = (e) => setHexaFirst(e);



  return (
    <FileEditorContext.Provider
      value={{
        editorRef,
        colIndexRef,
        offsetWidth,
        lineNumber,
        hexaFirst,
        updateoffsetWidth,
        updatelineNumber,
        updatehexaFirst
        
      }}
    >
      {props.children}
    </FileEditorContext.Provider>
  );
};

export default FileEditorContext;
