import React, { useEffect, useState, useRef } from "react";
import { useContext } from "react";

import FileEditorContext from "../contextApi/FileEditorContext.js";
import "./Offset.css";
let prevWidth = 43;
let width=43;
const Offset = (props) => {
  // let prevWidth = 0;

  //Defining contextApi
  const fileEditorContext = useContext(FileEditorContext);
  const { editorRef, colIndexRef, offsetWidth } = fileEditorContext;

  //For Offset Scrolling
  useEffect(() => {
    console.log(editorRef.current.editor.session);
    editorRef.current.editor.session.on("changeScrollLeft", (e) => {
      const colIndexDiv = colIndexRef.current;
      const scrollLeft = e;
      colIndexDiv.scrollLeft = scrollLeft;

      // console.log("Horizontal scroll", e);
    });
    return () => {
      if (editorRef.current && editorRef.current?.editor) {
        editorRef.current?.editor.session.off("changeScrollLeft", () => null);
      }
    };
  }, []);

  //Making offset alignment with editor line width
  useEffect(() => {
    let observer;
    const aceEditorElem = document.getElementById("ace-editor");
    const rightIndexElem = document.querySelector(".right_index");
    if (aceEditorElem) {
      const lineNumbersGutter = aceEditorElem.children[1];
      console.log(lineNumbersGutter);
      if (lineNumbersGutter) {
        observer = new ResizeObserver((entries) => {
          const entry = entries[0];
          prevWidth = width;
          console.log("prevwidth:"+prevWidth);
          width = entry.target.clientWidth;
          console.log("gutter width: "+width);
          // if (width == 42 || width == 43) {
          //   prevWidth = width;
          // }
          const computedStyle = window.getComputedStyle(rightIndexElem);
          const currentMarginLeft =
            computedStyle.getPropertyValue("margin-left");
          const currentMarginLeftNumeric = parseFloat(currentMarginLeft);
          const newMarginLeftNumeric =
            currentMarginLeftNumeric + (width - prevWidth);
            // console.log("new offset width: "+newMarginLeftNumeric);
            console.log("width,prevwidth,currentMarginLeft,newMarginLeftNumeric",width,prevWidth,currentMarginLeft,newMarginLeftNumeric)  
         
          rightIndexElem.style.marginLeft = `${newMarginLeftNumeric}px`;
        });

        observer.observe(lineNumbersGutter);
      }
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, []);

  return (
    <div className="col_index" ref={colIndexRef}>
      <div className="right_index">
        {Array.from({ length: offsetWidth }).map((_, i) => (
          <div className="indexWrapper" key={i}>
            <div className="index_head_num">{i * 10}</div>
            <div className="index_data_num">0123456789</div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Offset;
