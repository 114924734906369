import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import {
  DefaultButton,
  Dialog,
  DialogType,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import FileEditorContext from "../contextApi/FileEditorContext.js";

const DialogBox = ({handleCtrlG}) => {
  const fileEditorContext = useContext(FileEditorContext);
  const { editorRef, updateoffsetWidth, lineNumber, updatelineNumber } =
    fileEditorContext;
  const [isOpen, setIsOpen] = useState(false);

  const handleLineNumberChange = (event, newValue) => {
    updatelineNumber(newValue);
  };
  const showDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.ctrlKey && e.key === "g") {
        e.preventDefault(); // Prevent the browser's default behavior
        // handleCtrlG(); // Trigger your custom "Go to Line" function
        updatelineNumber("");
        showDialog();
      }
    };

    // Attach the event listener to the window
    window.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleCtrlG]);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      console.log("Key Enter");
      document.getElementById("okClass").click();
    }
  };
  const handleOkClick = useCallback(() => {
    // You can perform actions when the "OK" button is clicked
    console.log("Line Number:", lineNumber);
    setIsOpen(false);
    handleCtrlG();
  }, [handleCtrlG]);
  return (
    <Dialog
      hidden={!isOpen}
      onDismiss={closeDialog}
      dialogContentProps={{
        type: DialogType.normal,
        title: "Enter Record number",
        // subText: 'Enter line number',
      }}
      modalProps={{
        isBlocking: false,
        styles: { main: { maxWidth: 450 } },
      }}
    >
      <TextField
        value={lineNumber}
        id="RecordNumberText"
        onChange={handleLineNumberChange}
        onKeyPress={handleKeyPress}
      />

      <Stack tokens={{ childrenGap: 10 }} horizontalAlign="stretch">
        {/* <Label>Enter line number:</Label> */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "25px",
          }}
        >
          <PrimaryButton text="OK" id="okClass" onClick={handleOkClick} />
          <DefaultButton text="Cancel" onClick={closeDialog} />
        </div>
      </Stack>
    </Dialog>
  );
};
export default DialogBox;
