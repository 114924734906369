import { getRecordLen } from "./getRecordLen";
const processChunk=(ebcdicBytes,lineNumber,endPoint,recordLength,vb,st,ed,start,end,dataSize,numRecords,currentPage,maxChunkSize)=>{
    let recordNum=endPoint;
    let iterator = 0,startIndex=0;
    let en;
    let prevRecordLength = 0;
    recordLength=Number(recordLength)
    // let recordLength;
    while (iterator < ebcdicBytes.length ) {
        if (vb) {
          prevRecordLength = recordLength;
          recordLength = getRecordLen(startIndex, ebcdicBytes);
        } 
        en = startIndex + recordLength;
        if (en < ebcdicBytes.length && recordLength > 0) {
          iterator += recordLength;
          recordNum+=1;
          if(recordNum==lineNumber){
            console.log(recordNum)
            return[true,recordNum,start,end,st,ed,numRecords,currentPage]
          }
        } else if (en === ebcdicBytes.length && recordLength > 0) {
          st[currentPage]=start;
          ed[currentPage]=end

          dataSize = start;
          dataSize += iterator;
          start = dataSize;
          end = start + maxChunkSize;
          numRecords[currentPage+1] = recordNum;

          currentPage+=1;
         break;
        } else {
          st[currentPage]=start;
          ed[currentPage]=end


          dataSize = start + iterator;
          start = dataSize;
          end = start + maxChunkSize;
          numRecords[currentPage+1] = recordNum;
          currentPage+=1

          
          break;
        }
        startIndex = iterator;
        if (recordLength <= 0 ) {
          start = start - prevRecordLength;
        }
      }
      return[false,recordNum,start,end,st,ed,numRecords,currentPage]
    
}
export {processChunk}