const foldUnFoldFun=(event,editorRef,wrapLengthRef, hexFirstChunkRef, hexSecondChunkRef)=>{
    const editor = editorRef.current.editor;
    console.log("in guttermouse down")
    const currentContent = editorRef.current.editor.getValue();
    editorRef.current.editor.session.setValue(currentContent);
    const target = event.domEvent.target;
    if (target.classList.contains("ace_gutter-cell")) {
      // target.classList.add("new-class");
         target.dataset.activeCell = "true";
    
      let lineNumber = event.getDocumentPosition().row;

      // const lineNumber =Number( event.domEvent.target.innerText)
      const column = 0; // Set the column to 0 to move the cursor to the start of the line
      editor.gotoLine(lineNumber-1, column, true); // Move the cursor to the clicked line
      let content=editor. session. getLine(lineNumber);
      editor.getSession().setUseWrapMode(true);
      let range;
      let hexF = "";
      let hexS = "";
      let length = 0;
      try{
         range = editorRef.current.editor.find(
          // editorRef.current.editor.session.getLine(lineNumber - 1)
          editorRef.current.editor.session.getLine(lineNumber)
  
        );
      }
      catch(e){
        range=
        {
          start: { row: lineNumber, column: 0 },
          end: { row: lineNumber, column: content.length },
        }

       
  
        if(content.length>wrapLengthRef){
        
          delete target.dataset.activeCell
          let contentEnd=0;
          for (let i = 0; i < hexFirstChunkRef.length; i++) {
            length += hexFirstChunkRef[i].length;
            if (lineNumber < length ) {
              if(i==0){
                hexF = hexFirstChunkRef[i][lineNumber];
                contentEnd=hexF.length;
              }
              else if(i==1){
                lineNumber=lineNumber-hexFirstChunkRef[0].length;
                hexF = hexFirstChunkRef[i][lineNumber];
                contentEnd=hexF.length;
              }
              else if(i==2){
                lineNumber=lineNumber-hexFirstChunkRef[0].length-hexFirstChunkRef[1].length;
                hexF = hexFirstChunkRef[i][lineNumber];
                contentEnd=hexF.length;
              }
              break;
            }
          }
       
          editor.session.replace(range , content.slice(0,contentEnd));




      }
    }
     
      

      if(content.length>wrapLengthRef){
      
        delete target.dataset.activeCell
        let contentEnd=0;
        for (let i = 0; i < hexFirstChunkRef.length; i++) {
          length += hexFirstChunkRef[i].length;
          if (lineNumber < length ) {
            if(i==0){
              hexF = hexFirstChunkRef[i][lineNumber];
              contentEnd=hexF.length;
            }
            else if(i==1){
              lineNumber=lineNumber-hexFirstChunkRef[0].length;
              hexF = hexFirstChunkRef[i][lineNumber];
              contentEnd=hexF.length;
            }
            else if(i==2){
              lineNumber=lineNumber-hexFirstChunkRef[0].length-hexFirstChunkRef[1].length;
              hexF = hexFirstChunkRef[i][lineNumber];
              contentEnd=hexF.length;
            }
            break;
          }
        }
     
        editor.session.replace(range , content.slice(0,contentEnd));
        return;
      }
      // target.dataset.activeCell = "true";
      for (let i = 0; i < hexFirstChunkRef.length; i++) {
        length += hexFirstChunkRef[i].length;
        if (lineNumber < length ) {
          if(i==0){
           hexF = hexFirstChunkRef[i][lineNumber];
           hexS = hexSecondChunkRef[i][lineNumber];
          }
          else if(i==1){
            lineNumber=lineNumber-hexFirstChunkRef[0].length;
            hexF = hexFirstChunkRef[i][lineNumber];
           hexS = hexSecondChunkRef[i][lineNumber];
          }
          else if(i==2){
            lineNumber=lineNumber-hexFirstChunkRef[0].length-hexFirstChunkRef[1].length;
            hexF = hexFirstChunkRef[i][lineNumber];
           hexS = hexSecondChunkRef[i][lineNumber];
          }
          break;
        }
      }
      let l = hexF.length;
      for (; l <= wrapLengthRef - 4; l++) {
        hexF += " ";
        hexS += " ";
        content += " ";
      }


      let replaceString = content + hexF + hexS;
      if (range != undefined && length!=0)
        editor.session.replace(range, replaceString);
    
    }
}
export{foldUnFoldFun}