const handleScroll = (loadNextChunkImmediately,editorRef,scrollEnd,handler,fileBlob,recLength,fileSize,currentPage,
  chunks,chunkSize,start,st,end,ed,numRecords) => {

    loadNextChunkImmediately = false;

    const editor = editorRef.current.editor;
    const scrollTop = editor.getSession().getScrollTop();

    // const scrollHeight = editor.getSession().getDocument().getLength() * editor.renderer.lineHeight;
    const scrollHeight =
      editor
        .getSession()
        .documentToScreenPosition(
          editor.getSession().getDocument().getLength(),
          0
        ).row *
        editor.renderer.lineHeight +
      editor.renderer.scrollBar.getWidth();
    const viewHeight = editor.renderer.$size.height;

    if (scrollTop + viewHeight >= scrollHeight - 200) {
      if (end > fileSize && currentPage >=numRecords.length-1) return;
      scrollEnd = 2;
      handler(fileBlob, recLength, fileSize,start,end,currentPage,loadNextChunkImmediately,scrollEnd,st,ed,numRecords);
    } else if (
      scrollTop === 0 &&
      (currentPage >= 3 || (currentPage < 3 && currentPage > 0))
    ) {
      const value = editor.getOption("firstLineNumber");

      if (value === 1) {
        return [0];
      }

      currentPage--;
      scrollEnd = 3;
      if (chunks.length == chunkSize) {
        if (currentPage >= 3) {
          start = st[currentPage - 3];
          end = ed[currentPage - 3];
          currentPage = currentPage - 3;
        } else {
          start = st[currentPage];
          end = ed[currentPage];
        }
      } else if (chunks.length < chunkSize) {
        var num = chunks.length;
        if (currentPage >= num) {
          start = st[currentPage - num];
          end = ed[currentPage - num];
          currentPage = currentPage - num;
        } else {
          start = st[currentPage];
          end = ed[currentPage];
        }
      }
      handler(fileBlob, recLength, fileSize,start,end,currentPage,loadNextChunkImmediately,scrollEnd,st,ed,numRecords);
    }
    // return [scrollEnd,currentPage,start,end,loadNextChunkImmediately]
  };

  export {handleScroll}