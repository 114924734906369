import { getRecordLen } from "./getRecordLen";

const readCompleteRecordsFromChunk = (ebcdicBytes, recordLength, startIndex,vb,start,end,dataSize,maxChunkSize) => {
    let iterator = 0;
    let en;
    let prevRecordLength = 0;
   

    while (iterator < ebcdicBytes.length) {
      if (vb) {
        prevRecordLength = recordLength;
        recordLength = getRecordLen(startIndex, ebcdicBytes);
      }
      //what if reclength 0 ?????

      en = startIndex + recordLength;
      if (en < ebcdicBytes.length && recordLength > 0) {
        iterator += recordLength;
   
      } else if (en === ebcdicBytes.length && recordLength > 0) {
        // iterator += recordLength;
        dataSize = start;
        dataSize += iterator;
        start = dataSize;
        end = start + maxChunkSize;
        break;
      } else {

        dataSize = start + iterator;
        start = dataSize;
        end = start + maxChunkSize;

        break;
      }
      startIndex = iterator;
    }

    //when recLen is 0??????

    if (recordLength <= 0) {
      start = start - prevRecordLength;
    }

    return [ebcdicBytes.slice(0, iterator + recordLength), dataSize,start,end];
  };
  export {readCompleteRecordsFromChunk}
