import FileEditor from "./components/aceeditor/FileEditor";
import "./App.css";
import { FileEditorApi } from "./components/contextApi/FileEditorContext";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <FileEditorApi>
          <FileEditor />
        </FileEditorApi>
      </header>
    </div>
  );
}

export default App;
