
import { processChunk } from "./processChunk";
const goToLinehandler = ( lineNumber, endPoint,recLength,vb,fileBlobRef,st,ed,start,end,dataSize,
    numRecords,currentPage,maxChunkSize,scrollEnd,handler,fileSize,loadNextChunkImmediately) => {
  
    let result;
     let promise = new Promise(getBuffer(fileBlobRef));
     // Wait for promise to be resolved, or log error.
     promise
       .then(function (data) {
         [result,endPoint,start,end,st,ed,numRecords,currentPage]=processChunk(data,lineNumber,endPoint,recLength,vb,st,ed,start,
            end,dataSize,numRecords,currentPage,maxChunkSize,scrollEnd,handler,fileSize,loadNextChunkImmediately);
      
         if(result==false){
           goToLinehandler( lineNumber, endPoint,recLength,vb,fileBlobRef,st,ed,start,end,dataSize,numRecords,currentPage,maxChunkSize,scrollEnd,handler,fileSize,loadNextChunkImmediately)
         }
         else{
           scrollEnd=4;
           handler(fileBlobRef, recLength, fileSize,start,end,currentPage,loadNextChunkImmediately,scrollEnd,st,ed,numRecords)
           
           
         }
         
       })
       .catch(function (err) {});
 
       function getBuffer(fileData) {
         return function (resolve) {
           let reader = new FileReader();
           reader.readAsArrayBuffer(fileData.slice(start, end));
           reader.onload = function () {
             resolve(new Uint8Array(reader.result));
           };
         };
       }
   };
   export{goToLinehandler}