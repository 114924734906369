const getPreviousChunksContainingThisRecord = (lineNumber, startPoint,recLength,currentPage,start,end,st,ed,numRecords,handler,scrollEnd,
    loadNextChunkImmediately,fileSize,fileBlobRef) => {

    currentPage--; 
    start=st[currentPage]
    end=ed[currentPage]
         console.log( numRecords[currentPage-1])
         if(lineNumber<numRecords[currentPage-1])
         {
          startPoint=numRecords[currentPage-1]
          getPreviousChunksContainingThisRecord( lineNumber, startPoint,recLength,currentPage,start,end,st,ed,numRecords,handler,scrollEnd,
            loadNextChunkImmediately,fileSize,fileBlobRef)
         }
         else{
          currentPage--; 
          start=st[currentPage]
          end=ed[currentPage]
           handler(fileBlobRef, recLength, fileSize,start,end,currentPage,loadNextChunkImmediately,scrollEnd,st,ed,numRecords) 
         }
         
      
   };
export {getPreviousChunksContainingThisRecord}  