
import { useContext } from "react";
import FileEditorContext from "../contextApi/FileEditorContext.js";

export const useLoadPage=()=>{
  const fileEditorContext = useContext(FileEditorContext);

const loadPage = (fileContent,editorRef,scrollEnd,setChunks,chunks,start,st,end,ed,setFileContent,setHexaSecond,
  hexaSecond,chunkSize,currentPage,setHexaFirstChunks,setHexaSecondChunks,numRecords,loadNextChunkImmediately,lineNumber) => {
      

    const { hexaFirst,updatehexaFirst } = fileEditorContext;

    if (fileContent !== "" && fileContent != "   \n") {
      const currentContent = editorRef.current.editor.getValue();
      editorRef.current.editor.session.setValue(currentContent);
      // editorRef.current.editor.session.setValue("abcd"); // clearing the editor content
      if (scrollEnd === 1) {
        // user is at chunk 1
        //initial
        // editorRef.current.editor.session.setValue([...chunks, fileContent].join());
        setChunks((oldChunks) => [ fileContent]);
        setHexaFirstChunks((oldChunks) => [ hexaFirst]);
        setHexaSecondChunks((oldChunks) => [ hexaSecond]);
        editorRef.current.editor.setOption(
          "firstLineNumber",
          1
        );

      } else if (scrollEnd === 2) {
        // user is at chunk 2
        //scroll thumb near to bottom
        if (chunks.length < chunkSize) {
          // editorRef.current.editor.session.setValue([...chunks, fileContent].join());
          setChunks((oldChunks) => [...oldChunks, fileContent]);
          setHexaFirstChunks((oldChunks) => [...oldChunks, hexaFirst]);
          setHexaSecondChunks((oldChunks) => [...oldChunks, hexaSecond]);
        } else {
          currentPage--;
          start = st[currentPage];
          end = ed[currentPage];
          setChunks((oldChunks) => [oldChunks[2]]);
          setHexaFirstChunks((oldChunks) => [oldChunks[2]]);
          setHexaSecondChunks((oldChunks) => [oldChunks[2]]);
          editorRef.current.editor.setOption(
            "firstLineNumber",
            numRecords[currentPage - 1]
          );
        }
      } else if (scrollEnd == 3) {
        //scroll thumb is moving upside=>what to do????
        if (chunks.length == chunkSize) {
          loadNextChunkImmediately = true;
          setChunks((oldChunks) => [fileContent]);
          setHexaFirstChunks((oldChunks) => [hexaFirst]);
          setHexaSecondChunks((oldChunks) => [hexaSecond]);
          //  editorRef.current.editor.scrollToLine(numRecords[currentPage+1], true, true, () => {});
          const adjustedLineNumber =
            numRecords[currentPage] -
            1 +
            editorRef.current.editor.getFirstVisibleRow();
          editorRef.current.editor.gotoLine(adjustedLineNumber + 1);
          editorRef.current.editor.setOption(
            "firstLineNumber",
            numRecords[currentPage - 1]
          );
        } else if (chunks.length < chunkSize) {
          loadNextChunkImmediately = true;
          setChunks((oldChunks) => [fileContent]);
          setHexaFirstChunks((oldChunks) => [hexaFirst]);
          setHexaSecondChunks((oldChunks) => [hexaSecond]);
          const adjustedLineNumber =
            numRecords[currentPage] -
            1 +
            editorRef.current.editor.getFirstVisibleRow();

          editorRef.current.editor.gotoLine(adjustedLineNumber + 1);
          editorRef.current.editor.setOption(
            "firstLineNumber",
            numRecords[currentPage - 1]
          );
          // editorRef.current.editor.scrollToLine(numRecords[currentPage+2], true, true, () => {});
          
          }
        
      } else if (scrollEnd === 4) {
        // user is at chunk 1
        //initial
        // editorRef.current.editor.session.setValue([...chunks, fileContent].join());
        setChunks((oldChunks) => [ fileContent]);
        setHexaFirstChunks((oldChunks) => [ hexaFirst]);
        setHexaSecondChunks((oldChunks) => [ hexaSecond]);
        editorRef.current.editor.setOption(
          "firstLineNumber",
          numRecords[currentPage - 1]
        );
        const adjustedLineNumber =
          lineNumber -
          1 +
          editorRef.current.editor.getFirstVisibleRow();
          
        editorRef.current.editor.gotoLine(adjustedLineNumber + 1);
        console.log(numRecords[currentPage-1],lineNumber,numRecords[currentPage])
        editorRef.current.editor.gotoLine(lineNumber-numRecords[currentPage-1]+1, 0, true);
      } else if (scrollEnd === 5) {
        // user is at chunk 1
        //initial
        // editorRef.current.editor.session.setValue([...chunks, fileContent].join());
        const currentContent = editorRef.current.editor.getValue();
      editorRef.current.editor.session.setValue("");
        setChunks((oldChunks) => [ fileContent]);
        setHexaFirstChunks((oldChunks) => [ hexaFirst]);
        setHexaSecondChunks((oldChunks) => [ hexaSecond]);
        editorRef.current.editor.setOption(
          "firstLineNumber",
          numRecords[currentPage - 1]
        );
        editorRef.current.editor.session.setValue(fileContent);
        console.log(editorRef.current.editor.session.getLength())
        console.log(numRecords[currentPage-1],lineNumber,numRecords[currentPage-1])
        if(numRecords[currentPage-1]!=1)
        editorRef.current.editor.gotoLine(lineNumber-numRecords[currentPage-1]+1, 0, true);
        else{
        editorRef.current.editor.gotoLine(lineNumber, 0, true);
         }
      } 

      setFileContent("");
      updatehexaFirst([]);
      setHexaSecond([]);
    }
    return[currentPage,start,end,loadNextChunkImmediately]
  };
  return {loadPage};
}