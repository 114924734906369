import { getRecordLen } from "./getRecordLen";
import { isUnprintable } from "./isUnprintable";
import { e2UtfLookup } from "../constants/lookup";

const convert2Ascii=(ebcdicBytes, recordLength, fsize, vb, previous,numRecords,currentPage)=> {
    let flag = 0;
    let lines = [];
    let hexSecond = [];
    let hexFirst = [];

    if (ebcdicBytes.length < 4) {
      lines.push("May not be a valid file");
      return [lines];
    }
    //checking file is ebcidic(fb/vb) or not
    if (vb === false && recordLength === 0) {
      lines.push(
        "May not be a valid file.FB file record length value should not be 0"
      );
      return [lines];
    }
    if (vb === false && fsize % recordLength != 0) {
      lines.push("May not be a valid file.file was not fixed Block");
      return [lines];
    }

    let startIndex = 0;
    let len = 0;
    flag=recordLength;

    /*
      read first 4 bytes to figure out if it is variable block file or not
    */
    if (recordLength == 0) {
      if (ebcdicBytes[3] != 0 || ebcdicBytes[2] != 0) {
        lines.push("Record length was zero but file was not Variable Block");
        return [lines];
      }
      // calculate record length
      recordLength = getRecordLen(startIndex, ebcdicBytes);
      if (flag < recordLength) {
        flag = recordLength;
      }

      if (recordLength > 32756 || recordLength < 0) {
        lines.push("Record length was zero but file was not Variable Block");
        return [lines];
      }
      vb = true;
      startIndex = 4; // skip first 4 bytes for VB Files
      len = 3;
    }

    let asciiBytes = [];
    let lineChars = [];
    let hexaLineFirst = [];
    let hexaLineSecond = [];

    for (let i = startIndex; i < ebcdicBytes.length; i++) {
      len++;
      asciiBytes[i] = e2UtfLookup[ebcdicBytes[i]];

      if (isUnprintable(ebcdicBytes[i])) {
        asciiBytes[i] = 46;
      }

      lineChars.push(String.fromCharCode(asciiBytes[i]));
      let hexa = ebcdicBytes[i].toString(16);
      hexa = hexa.toUpperCase();
      if (hexa.length == 1) {
        hexaLineFirst.push(0);
        hexaLineSecond.push(hexa[0]);
      } else {
        hexaLineFirst.push(hexa[0]);
        hexaLineSecond.push(hexa[1]);
      }

      if (len === recordLength-1 && vb===true) {
        i++;
        len++;
        lines.push(lineChars.join(""));
        hexFirst.push(hexaLineFirst.join(""));
       
        hexSecond.push(hexaLineSecond.join(""));
        lineChars = [];
        hexaLineFirst = [];
        hexaLineSecond = [];
        len = 0;

        if (vb) {
          recordLength = getRecordLen(i, ebcdicBytes);
          if (flag < recordLength) {
            flag = recordLength;
          }
          if (recordLength <= 0) break;
          i += 3; // skip 4 bytes --> 3 + 1 will be added by the for loop
          len = 3;
        }
      }
      else if(len === recordLength){
        lines.push(lineChars.join(""));
        hexFirst.push(hexaLineFirst.join(""));
       
        hexSecond.push(hexaLineSecond.join(""));
        lineChars = [];
        hexaLineFirst = [];
        hexaLineSecond = [];
        len = 0;

        if (vb) {
          recordLength = getRecordLen(i, ebcdicBytes);
          if (flag < recordLength) {
            flag = recordLength;
          }
          if (recordLength <= 0) break;
          i += 3; // skip 4 bytes --> 3 + 1 will be added by the for loop
          len = 3;
        }
      }
    }
len++;
if (len === recordLength) {
  lines.push(lineChars.join(""));
  hexFirst.push(hexaLineFirst.join(""));
  hexSecond.push(hexaLineSecond.join(""));
  lineChars = [];
  hexaLineFirst = [];
  hexaLineSecond = [];
}
    previous = numRecords[currentPage];
    previous += lines.length;
    numRecords[currentPage + 1] = previous;
    asciiBytes = [];
    const returnedLines = [...lines];
    const returnedHexFirstLines = [...hexFirst];
    const returnedHexSecondLines = [...hexSecond];
    lines.length = 0;
    hexFirst.length = 0;
    hexSecond.length = 0;
    lines = [];
    hexFirst = [];
    hexSecond = [];
    return [
      [...returnedLines],
      [...returnedHexFirstLines],
      [...returnedHexSecondLines],
      flag,
      previous,
      numRecords
    ];
  }
  export {convert2Ascii}