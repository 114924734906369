import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";

import NavBar from "../navbar/NavBar.js";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-asciidoc"; // For plain text mode
import "ace-builds/src-noconflict/theme-monokai";
import { e2UtfLookup } from "../constants/lookup";
import "./FileEditor.css";
import "brace/ext/searchbox";
import {
  DefaultButton,
  Dialog,
  DialogType,
  PrimaryButton,
  Stack,
  TextField,
  IconButton,
  Label,
} from "@fluentui/react";
import { useLoadPage } from "../utility/LoadPage";
import { handleScroll } from "../utility/handleScroll";
import { readCompleteRecordsFromChunk } from "../utility/ReadCompleteRecordsFromChunk";
import { convert2Ascii } from "../utility/convert2Ascii";
// import { conversionHandler } from "../utility/conversionHandler";
import { foldUnFoldFun } from "../utility/foldUnFoldFun";
import { getPreviousChunksContainingThisRecord } from "../utility/getPreviousChunksContainingThisRecord";
import { goToLinehandler } from "../utility/goToLinehandler";
import Offset from "./Offset.js";
import "./Offset.css";
import FileEditorContext from "../contextApi/FileEditorContext.js";
import DialogBox from "./DialogBox.js";

let loadNextChunkImmediately = false;

const maxChunkSize = 3 * 1024 * 1024;
// const maxChunkSize = 300800

let start = 0;
let counter = 0;
let end = maxChunkSize;
let dataSize = 0;
let previous = 1;
let scrollEnd = 1;
let chunkSize = 3;
let vb = false;
let st = [];
let ed = [];
st[0] = 0;
let numRecords = [];
let currentPage = 0;
numRecords[0] = 1;

let isEbcidic = true;
let offsetWidth = 20;

const FileEditor = () => {
  //Defining contextApi
  const fileEditorContext = useContext(FileEditorContext);
  const { editorRef, updateoffsetWidth,lineNumber,hexaFirst,updatehexaFirst } = fileEditorContext;
  const {loadPage}=useLoadPage();

  const [isFileUploaded,setisFileUploaded]=useState(false);

  let prevWidth = 0;

  const [fileContent, setFileContent] = useState("");
  const [fileBlob, setFileBlob] = useState(null);
  const [chunks, setChunks] = useState([]);
  const [recLength, setRecLength] = useState(0);
  const [fileSize, setFileSize] = useState(0);
  const [hexaFirstchunks, setHexaFirstChunks] = useState([]);
  const [hexaSecondchunks, setHexaSecondChunks] = useState([]);
  
  const [hexaSecond, setHexaSecond] = useState([]);
  const [wrapLength, setWrapLength] = useState(0);

  const [clickLocation, setClickLocation] = useState(null);
  const [fileName, setFileName] = useState(null);
  const colIndexRef = useRef();
  var styles = `
  .ace-tm {
    background-color: #FFFFFF;
    color: #f93f26;
}
`;
var textColourAfterUploadingDifferentFile = `
.ace-tm {
  background-color: #FFFFFF;
  color: black;
}
`;

  const hexFirstChunkRef = useRef(null);
  const hexSecondChunkRef = useRef(null);
  const wrapLengthRef = useRef(null);
  const fileBlobRef = useRef(null);

  useEffect(() => {
    fileBlobRef.current = fileBlob;
  }, [fileBlob]);

  //For updating the offset width
  useEffect(() => {
    wrapLengthRef.current = wrapLength;
    if (wrapLength > 0 && wrapLength / 10 > 20) {
      let temp = wrapLength;
      temp = parseInt(temp / 10) + 1;
      updateoffsetWidth(temp);
    }
  }, [wrapLength]);

  useEffect(() => {
    hexFirstChunkRef.current = hexaFirstchunks;
    hexSecondChunkRef.current = hexaSecondchunks;
  }, [hexaFirstchunks, hexaSecondchunks]);

  const handler = (
    fileBlob,
    recLen,
    fsize,
    initial,
    terminal,
    presentPageNo,
    loadNextChunk,
    scroollPoint,
    stArr,
    edArr,
    numRecordsArr
  ) => {
    start = initial;
    end = terminal;
    currentPage = presentPageNo;
    loadNextChunkImmediately = loadNextChunk;
    scrollEnd = scroollPoint;
    st = stArr;
    ed = edArr;
    numRecords = numRecordsArr;
    let output;
    let promise = new Promise(getBuffer(fileBlob));
    // Wait for promise to be resolved, or log error.
    promise
      .then(function (data) {
        st[currentPage] = start;
        ed[currentPage] = end;
        let totalRecords;
        [data, dataSize, start, end] = readCompleteRecordsFromChunk(
          data,
          recLen,
          0,
          vb,
          start,
          end,
          dataSize,
          maxChunkSize
        );
        // [data]=read(data, recLen, 0);

        let [
          lines,
          hexFirst,
          hexSecond,
          flag,
          previousValue,
          numRecordsInChunk,
        ] = convert2Ascii(
          data,
          recLen,
          fsize,
          vb,
          previous,
          numRecords,
          currentPage
        );
        previous = previousValue;
        numRecords = numRecordsInChunk;

        data = null;
        output = "";
        let j;
        for (j = 0; j < lines.length - 1; j++) {
          output += lines[j] + "\n";
        }
        if(lines.length==1){
          output += lines[j] ;
        }
        else if(end>fsize && currentPage >=numRecords.length-2){
          output += lines[j] ;
        }
        else{
          output += lines[j]+ "\n";
        }
        
        if (flag > wrapLength) {
          setWrapLength(flag);
        }

        if (lines.length != 1) {
          updatehexaFirst([...hexFirst]);
          setHexaSecond([...hexSecond]);
          lines.length = 0;
          hexFirst.length = 0;
          hexSecond.length = 0;

          lines = [];
          hexFirst = [];
          hexSecond = [];
        } else if (lines.length == 1) {
          let wrapLen = output.length;
          setWrapLength(wrapLen);
          var styleSheet = document.createElement("style");
          styleSheet.innerText = styles;
          document.head.appendChild(styleSheet);
        }

        setFileContent(output);
        currentPage += 1;
      })
      .catch(function (err) {});

    function getBuffer(fileData) {
      return function (resolve) {
        let reader = new FileReader();
        console.log("start and end", start, end);
        reader.readAsArrayBuffer(fileData.slice(start, end));
        reader.onload = function () {
          resolve(new Uint8Array(reader.result));
        };
      };
    }
  };

  // useEffect(()=>{
  //   if(fileBlob!==null)
  //   isFileUploaded=true;
  //   console.log("file changed")
  // },[fileBlob])



  // const handleSaveFile = () => {
  //   const blob = new Blob([fileContent], { type: "text/plain;charset=utf-8" });
  //   saveAs(blob, "uploaded-file.txt");
  // };


  const handleFileUpload = (fileData, recLen, flType) => {
    let fd = fileData[0];
    setFileSize(fd.size);
    let rl = Number(recLen);
    setFileBlob(fd);
    setRecLength(rl);
    localStorage.setItem("recLength", rl);
    if (flType == "fb") {
      vb = false;
    } else {
      vb = true;
    }
    setChunks([]);
    setHexaFirstChunks([]);
    setHexaSecondChunks([]);
    // offsetWidth = 20;
    var styleSheet = document.createElement("style");
    styleSheet.innerText = textColourAfterUploadingDifferentFile;
    document.head.appendChild(styleSheet);
    previous=1
    dataSize=0;
    start=0;
    end=maxChunkSize;
    currentPage=0;
    st=[];
    ed=[];
    numRecords=[];
    numRecords[0] = 1;
    st[0] = 0;
    console.log("******width,prevwidth,currentMarginLeft,newMarginLeftNumeric****************")
    scrollEnd=1;
    handler(
      fd,
      rl,
      fd.size,
      start,
      end,
      currentPage,
      loadNextChunkImmediately,
      scrollEnd,
      st,
      ed,
      numRecords
    );
  };

  useEffect(() => {
    [currentPage, start, end, loadNextChunkImmediately] = loadPage(
      fileContent,
      editorRef,
      scrollEnd,
      setChunks,
      chunks,
      start,
      st,
      end,
      ed,
      setFileContent,
      setHexaSecond,
      hexaSecond,
      chunkSize,
      currentPage,
      setHexaFirstChunks,
      setHexaSecondChunks,
      numRecords,
      loadNextChunkImmediately,
      lineNumber
    );
  }, [fileContent]);

  useEffect(() => {
    editorRef.current.editor.session.setOption("wrap", wrapLength);
    // editorRef.current.editor.gotoLine(1520);
  }, [wrapLength]);

  function debounce(method, delay) {
    clearTimeout(method._tId);
    method._tId = setTimeout(function () {
      method();
    }, delay);
  }

  const handleScrollFunction = () => {
    handleScroll(
      loadNextChunkImmediately,
      editorRef,
      scrollEnd,
      handler,
      fileBlob,
      recLength,
      fileSize,
      currentPage,
      chunks,
      chunkSize,
      start,
      st,
      end,
      ed,
      numRecords
    );
  };

  const handleCtrlG = useCallback(() => {
    console.log("Ctrl+R pressed!");
    const editor = editorRef.current.editor;
    let numRecordsLength = numRecords.length;
    console.log(numRecordsLength - 1, numRecords[numRecordsLength - 1]);
    const startPoint = editor.getOption("firstLineNumber");
    let endPoint = startPoint + numRecords[currentPage];
    endPoint = endPoint - startPoint;

    if (lineNumber != 0) {
      if (lineNumber >= startPoint && lineNumber < endPoint) {
        // if (!isNaN(lineNumber) && editorRef.current) {
        editorRef.current.editor.gotoLine(lineNumber - startPoint + 1, 0, true);
        // }
      } else {
        if (lineNumber > endPoint) {
          scrollEnd = 4;
          //  let recl=localStorage.getItem("recLength");
          //  recl=Number(recl)
          goToLinehandler(
            lineNumber,
            endPoint,
            recLength,
            vb,
            fileBlobRef.current,
            st,
            ed,
            start,
            end,
            dataSize,
            numRecords,
            currentPage,
            maxChunkSize,
            scrollEnd,
            handler,
            fileSize,
            loadNextChunkImmediately
          );
        } else if (lineNumber < startPoint) {
          scrollEnd = 5;
          if (vb === true) {
            currentPage--;
          }

          let recl = localStorage.getItem("recLength");
          recl = Number(recl);
          getPreviousChunksContainingThisRecord(
            lineNumber,
            startPoint,
            recLength,
            currentPage,
            start,
            end,
            st,
            ed,
            numRecords,
            handler,
            scrollEnd,
            loadNextChunkImmediately,
            fileSize,
            fileBlobRef.current
          );
        }
      }
    }
  }, [recLength, lineNumber]);

  useEffect(() => {
    if (
      editorRef.current &&
      hexFirstChunkRef.current &&
      hexSecondChunkRef.current
    ) {
      const editor = editorRef.current.editor;
      editorRef.current.editor.off("guttermousedown");
      // Attach an event listener for gutter click
      editor.on("guttermousedown", (event) => {
        foldUnFoldFun(
          event,
          editorRef,
          wrapLengthRef.current,
          hexFirstChunkRef.current,
          hexSecondChunkRef.current
        );
      });
    }

    return () => {
      if (editorRef.current?.editor) {
        editorRef.current.editor.off("guttermousedown", () => {
          console.log("cleared");
        });
      }
    };
  }, []);

  // For handling the Search Option
  useEffect(() => {
    const editor = editorRef.current.editor;
    const handleCtrlF = (event) => {
      if (event.ctrlKey && event.key === "f") {
        event.preventDefault();
        editor.execCommand("find");
      }
    };
    // Add event listener for "Ctrl+F" key press
    document.addEventListener("keydown", handleCtrlF);

    // Cleanup: Remove the event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", handleCtrlF);
    };
  }, []);

  const handleFileName = (data) => {
    setFileName(data);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      console.log("Key Enter")
      document.getElementById("okClass").click();
    }
  }


const handleChange=()=>{
  setisFileUploaded(false);
}

  return (
    <div style={{ paddingRight: 0, width: "100%" }}>
      <NavBar handler={handleFileUpload} handleFileName={handleFileName} />
      <Offset isFileUploaded={isFileUploaded} handleChange={handleChange}/>

      <div id="editor">
        <AceEditor
          className="sub-editor"
          mode="asciidoc"
          highlightActiveLine={true}
          // value={pages.join("")} // Combine the content of all loaded pages
          value={chunks.join("")} // Combine the content of all loaded pages
          showPrintMargin={false}
          scrollMargin={true}
          height="84.6vh"
          width="100%"
          fontSize={15}
          ref={editorRef}
          onScroll={() => {
         
            debounce(
              handleScrollFunction,
              loadNextChunkImmediately ? 0 : 500
            );
        }}
          // editorProps={{ $blockScrolling: Infinity }}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            showLineNumbers: true,
            useWorker: false,
          }}
        />
      </div>
      {/* </div> */}

      <div className="file-loc">
        <div className="file-name">
          {fileName && <div className="file-content">{fileName}</div>}
        </div>
      </div>
      <div>
        <DialogBox handleCtrlG={handleCtrlG}/>
      </div>
    </div>
  );
};
export default FileEditor;